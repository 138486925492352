import googleDocs from './normalizers/googleDocs';
import msWord from './normalizers/msWord';
const NORMALIZERS = [msWord, googleDocs];
const normalizeExternalHTML = doc => {
  if (doc.documentElement) {
    NORMALIZERS.forEach(normalize => {
      normalize(doc);
    });
  }
};
export default normalizeExternalHTML;
